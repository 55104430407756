import { onMounted } from 'vue';
import { useGtag } from "vue-gtag-next";
import { Gtag, TrackPage } from '@/modules/portal/toolbox/interfaces/tracks-interface'

export function useTrack(props: TrackPage) {
    const { page_path, page_title, page_location } = props;
    const { pageview, event, exception } = useGtag();

    const trackPage = () => {
      if (page_path) {
        pageview({ page_path, page_title, page_location });
      }
    };

    const trackEvent = (action: Gtag.eventName, eventParams?: Gtag.eventParams) => {
      event(action, eventParams);
    };

    const trackException = (description?: string, fatal?: boolean) => {
      exception({
        description,
        fatal
      });
    };

    onMounted(() => {
      trackPage();
    });

    return { trackPage, trackEvent, trackException };
}
