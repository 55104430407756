<template>
  <a
    class="custom-link"
    v-if="isExternalLink"
    :href="link.to"
    :class="'normal-link'"
    target="_blank"><span @click="(e) => trackEvent(e, link.track)">{{link.label}}</span></a>

  <router-link
    v-else
    :to="route"
    custom
    v-slot="{ isActive, href, navigate }">
    <a class="custom-link"
      :class="isActive ? 'is-active': 'normal-link'"
      :href="href" @click="(e) => trackEvent(e, link.track, navigate)">
      <span>{{link.label}}</span>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  methods: {
    trackEvent(e, tack, navigate = undefined) {
      this.$gtag.event(tack);
      return navigate && navigate(e);
    }
  },
  computed: {
    isExternalLink() {
      return this.link.to.startsWith('http');
    },
    route() {
      return this.link.id === undefined
          ? { name: this.link.to }
          : { name: this.link.to, params: { id: this.link.id } };
    }
  }
};
</script>

<style lang="sass">
a
  text-decoration: none
.is-active
  color: #42b983
.normal-link
  color: $color-white
</style>
