import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  emits: ['on:click'],
  props: {
    as: {
      type: String,
      default: 'button'
    },
    href: {
      type: String,
      default: '#'
    },
    loading: {
      type: Boolean,
      default: false
    },
    class: {
      type: String,
      default: ''
    },
    download: {
      type: [String, Boolean],
      default: false
    },
    target: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return {
      as: props.as,
      clasName: props.class,
      href: props.href,
      download: props.download,
      target: props.target
    };
  }
});