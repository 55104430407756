<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
  >
    <path d="M19 3H8C6.346 3 5 4.346 5 6v1H4a1 1 0 100 2h1v2H4a1 1 0 100 2h1v2H4a1 1 0 100 2h1v1c0 1.654 1.346 3 3 3h11c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3zM7 6c0-.551.449-1 1-1v2H7V6zm0 3h1v2H7V9zm0 4h1v2H7v-2zm0 5v-1h1v2c-.551 0-1-.449-1-1zm13 0c0 .551-.449 1-1 1H9V5h10c.551 0 1 .449 1 1v12z" />
    <path d="M16 10.5 A2 2 0 0 1 14 12.5 A2 2 0 0 1 12 10.5 A2 2 0 0 1 16 10.5 z" />
    <path d="M14 13.356c-1.562 0-2.5.715-2.5 1.429 0 .357.938.715 2.5.715 1.466 0 2.5-.357 2.5-.715 0-.714-.98-1.429-2.5-1.429z" />
  </svg>
</template>
